<template>
  <div>
    <a-row :gutter="16">
      <template v-if="isStudentView">
        <a-col :xs="24" :lg="7">
          <Card style="min-height: 464px;">
            <div class="cui__utils__heading mb-0">
              <strong>LIST OF SUBJECTS</strong>
            </div>
            <div class="text-muted mb-3">You can choose the subjects here</div>
            <hr />
            <div
              id="subject-list"
              ref="subject-list"
              v-if="chartData.labels.length && chartData.series.length"
            ></div>
            <div v-else class="my-5">
              <EmptyState
                heading="No Data"
                description="You have no list of subjects"
              />
            </div>
          </Card>
        </a-col>
        <a-col :xs="24" :lg="17">
          <Card>
            <div class="cui__utils__heading mb-0">
              <strong>SUMMARY OF SUBJECTS</strong>
            </div>
            <div class="text-muted mb-3">
              You can see summary of subjects here
            </div>
            <hr />
            <div :style="{
              'overflow-x': isMobile ? 'scroll' : null,
            }">
              <div
                class="chart"
                v-if="chartData.labels.length && chartData.series.length"
              ></div>
              <div v-else class="my-5">
                <EmptyState
                  heading="No Data"
                  description="You have no assignment in progress"
                />
              </div>
            </div>
          </Card>
        </a-col>
      </template>
      <template v-else>
        <div
          id="subject-list2"
          ref="subject-list2"
          v-if="chartData.labels.length && chartData.series.length"
        ></div>
        <div
          class="chart"
          v-if="chartData.labels.length && chartData.series.length"
        >
        </div>
      </template>
    </a-row>
  </div>
</template>

<script>
import Chartist from 'chartist'
import ChartistLegend from 'chartist-plugin-legend'
import Card from '@/components/app/Card'
import EmptyState from '@/components/app/EmptyState'
export default {
  components: {
    Card,
    EmptyState,
  },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    isStudentView: {
      type: Boolean,
      required: true,
    },
  },
  mounted() {
    if (this.chartData.labels.length && this.chartData.series.length) {
      const targetSubjectList = this.isStudentView ? document.getElementById('subject-list') : document.getElementById('subject-list2')
      const chart = new Chartist.Line('.chart', {
        labels: this.chartData.labels,
        series: this.chartData.series,
      }, {
        height: this.isStudentView ? 300 : 400,
        fullWidth: true,
        chartPadding: {
          right: 40,
          left: 10,
        },
        plugins: [
          ChartistLegend({
            position: targetSubjectList,
          }),
        ],
      })

      let seq = 0
      const delays = 30
      const durations = 500

      // Once the chart is fully created we reset the sequence
      chart.on('created', function() {
        seq = 0
      })

      // On each drawn element by Chartist we use the Chartist.Svg API to trigger SMIL animations
      chart.on('draw', function(data) {
        seq++

        if (data.type === 'line') {
          // If the drawn element is a line we do a simple opacity fade in. This could also be achieved using CSS3 animations.
          data.element.animate({
            opacity: {
              // The delay when we like to start the animation
              begin: seq * delays + 1000,
              // Duration of the animation
              dur: durations,
              // The value where the animation should start
              from: 0,
              // The value where it should end
              to: 1,
            },
          })
        } else if (data.type === 'label' && data.axis === 'x') {
          data.element.animate({
            y: {
              begin: seq * delays,
              dur: durations,
              from: data.y + 100,
              to: data.y,
              // We can specify an easing function from Chartist.Svg.Easing
              easing: 'easeOutQuart',
            },
          })
        } else if (data.type === 'label' && data.axis === 'y') {
          data.element.animate({
            x: {
              begin: seq * delays,
              dur: durations,
              from: data.x - 100,
              to: data.x,
              easing: 'easeOutQuart',
            },
          })
        } else if (data.type === 'point') {
          data.element.animate({
            x1: {
              begin: seq * delays,
              dur: durations,
              from: data.x - 10,
              to: data.x,
              easing: 'easeOutQuart',
            },
            x2: {
              begin: seq * delays,
              dur: durations,
              from: data.x - 10,
              to: data.x,
              easing: 'easeOutQuart',
            },
            opacity: {
              begin: seq * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: 'easeOutQuart',
            },
          })
        } else if (data.type === 'grid') {
          // Using data.axis we get x or y which we can use to construct our animation definition objects
          const pos1Animation = {
            begin: seq * delays,
            dur: durations,
            from: data[data.axis.units.pos + '1'] - 30,
            to: data[data.axis.units.pos + '1'],
            easing: 'easeOutQuart',
          }

          const pos2Animation = {
            begin: seq * delays,
            dur: durations,
            from: data[data.axis.units.pos + '2'] - 100,
            to: data[data.axis.units.pos + '2'],
            easing: 'easeOutQuart',
          }

          const animations = {}
          animations[data.axis.units.pos + '1'] = pos1Animation
          animations[data.axis.units.pos + '2'] = pos2Animation
          animations.opacity = {
            begin: seq * delays,
            dur: durations,
            from: 0,
            to: 1,
            easing: 'easeOutQuart',
          }

          data.element.animate(animations)
        }
      })

      // For the sake of the example we update the chart every time it's created with a delay of 10 seconds
      chart.on('created', function() {
        if (window.__exampleAnimateTimeout) {
          clearTimeout(window.__exampleAnimateTimeout)
          window.__exampleAnimateTimeout = null
        }
        window.__exampleAnimateTimeout = setTimeout(chart.update.bind(chart), 30000)
      })
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.chart {
  margin-top: 20px;
  min-width: 500px;
}

#subject-list {
  margin-top: 20px;
  .ct-legend {
    width: 120%;
    list-style: none;
    text-align: left;
    margin-left: -40px;
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    list-style-position: inside;

    @media (max-width: 426px) {
      -webkit-columns: 1;
      -moz-columns: 1;
      columns: 1;
      list-style-position: inside;
    }

    li {
      position: relative;
      padding-left: 40px;
      margin-top: 25px;
      cursor: pointer;
      display: block;

      &.ct-series-6 {
        padding-top: 26px;

        @media (max-width: 426px) {
          padding-top: 0px;
        }
      }

      &::before {
        width: 30px;
        height: 20px;
        position: absolute;
        left: 0;
        content: "";
        border: 3px solid transparent;
        border-radius: 2px;
      }

      $series: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o);
      $colors: (
        #088080,
        #3fba83,
        #feff00,
        #fbac13,
        #453c3f,
        #59932b,
        #0644d3,
        #662eff,
        #fd3a94,
        #dda457,
        #ebd07d,
        #86787d,
        #f1f4f8,
        #d8af34,
        #6ed4d4,
        #479c83,
        #39e2af,
        #ca9c60,
        #c2741c,
      );
      @each $color in $colors {
        $i: index($colors, $color);
        &:nth-child(#{$i})::before {
          background-color: $color;
        }
        &.inactive:nth-child(#{$i})::before {
          background-color: transparent;
          border-color: $color;
        }
      }
      // @for $i from 0 through 100 {
      //   &:nth-child(#{i})::before {
      //     background-color: black;
      //   }
      //   &.inactive:nth-child(#{$i})::before {
      //     background-color: transparent;
      //     // border-color: rgb(random(255), random(255), random(255));
      //     border-color: black;
      //   }
      // }
    }

    .ct-legend-inside {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  g:not(.ct-grids):not(.ct-labels) g {
    &:nth-child(1) {
      .ct-point,
      .ct-line {
        stroke: #d70206;
      }
    }
    &:nth-child(2) {
      .ct-point,
      .ct-line {
        stroke: #f05b4f;
      }
    }
    &:nth-child(3) {
      .ct-point,
      .ct-line {
        stroke: #f4c63d;
      }
    }
    &:nth-child(1n + 4) {
      .ct-point,
      .ct-line {
        stroke: #f06292;
      }
    }
  }
}

#subject-list2 {
  padding: 20px 20px 0 20px;
  .ct-legend {
    width: 120%;
    list-style: none;
    text-align: left;
    margin-left: -40px;
    -webkit-columns: 4;
    -moz-columns: 4;
    columns: 4;
    list-style-position: inside;

    @media (max-width: 426px) {
      -webkit-columns: 2;
      -moz-columns: 2;
      columns: 2;
      list-style-position: inside;
    }

    li {
      position: relative;
      padding-left: 40px;
      margin-bottom: 10px;
      cursor: pointer;
      display: block;

      // &.ct-series-6 {
      //   padding-top: 26px;

      //   @media (max-width: 426px) {
      //     padding-top: 0px;
      //   }
      // }

      &::before {
        width: 30px;
        height: 20px;
        position: absolute;
        left: 0;
        content: "";
        border: 3px solid transparent;
        border-radius: 2px;
      }

      $series: (a, b, c, d, e, f, g, h, i, j, k, l, m, n, o);
      $colors: (
        #088080,
        #3fba83,
        #feff00,
        #fbac13,
        #453c3f,
        #59932b,
        #0644d3,
        #662eff,
        #fd3a94,
        #dda457,
        #ebd07d,
        #86787d,
        #f1f4f8,
        #d8af34,
        #6ed4d4,
        #479c83,
        #39e2af,
        #ca9c60,
        #c2741c,
      );
      @each $color in $colors {
        $i: index($colors, $color);
        &:nth-child(#{$i})::before {
          background-color: $color;
        }
        &.inactive:nth-child(#{$i})::before {
          background-color: transparent;
          border-color: $color;
        }
      }
      // @for $i from 0 through 100 {
      //   &:nth-child(#{i})::before {
      //     background-color: black;
      //   }
      //   &.inactive:nth-child(#{$i})::before {
      //     background-color: transparent;
      //     // border-color: rgb(random(255), random(255), random(255));
      //     border-color: black;
      //   }
      // }
    }

    .ct-legend-inside {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  g:not(.ct-grids):not(.ct-labels) g {
    &:nth-child(1) {
      .ct-point,
      .ct-line {
        stroke: #d70206;
      }
    }
    &:nth-child(2) {
      .ct-point,
      .ct-line {
        stroke: #f05b4f;
      }
    }
    &:nth-child(3) {
      .ct-point,
      .ct-line {
        stroke: #f4c63d;
      }
    }
    &:nth-child(1n + 4) {
      .ct-point,
      .ct-line {
        stroke: #f06292;
      }
    }
  }
}
</style>
